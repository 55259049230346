import React, { useState } from "react"
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import MusicPlayer from "./musicPlayer"

var styles = {
  card: {
    margin: "5px",
  },
  img: {
    margin: 0,
  },
  player: {
    songTitle: {
      fontSize: "24px",
      textAlign: "right",
      marginTo: "10px",
      marginBottom: 0,
    },
    artist: {
      fontSize: "18px",
      textAlign: "right",
      margin: 0,
    },
  },
}

const SongCard = props => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <div>
      <Card style={styles.card}>
        <CardImg
          style={styles.img}
          top
          width="25%"
          src={props.playlist[0].cover}
          alt="Card image cap"
        />
        <CardBody>
          <CardTitle style={styles.player.songTitle}>
            {props.playlist[0].title}
          </CardTitle>
          <CardSubtitle style={styles.player.artist}>
            {props.playlist[0].artist}
          </CardSubtitle>
          {/* <MusicPlayer playlist={props.playlist} /> */}
          <Button color="info" onClick={toggle}>
            Play
          </Button>
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {props.playlist[0].title} - {props.playlist[0].artist}
        </ModalHeader>
        <ModalBody>{props.playlist[0].embed}</ModalBody>
      </Modal>
    </div>
  )
}

export default SongCard
