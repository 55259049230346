import React, { Component } from "react"
import ReactPlayer from "react-player"
import { Button } from "reactstrap"

const styles = {
  rightText: {
    textAlign: "right",
  },
  player: {
    songTitle: {
      fontSize: "24px",
      textAlign: "right",
      marginTo: "10px",
      marginBottom: 0,
    },
    artist: {
      fontSize: "18px",
      textAlign: "right",
      margin: 0,
    },
  },
}

export default class MusicPlayer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      playing: false,
    }

    this.playController = this.playController.bind(this)
  }

  playController() {
    this.setState({
      playing: !this.state.playing,
    })
  }

  render() {
    let buttonText = ""
    if (this.state.playing) {
      buttonText = "Pause"
    } else {
      buttonText = "Play"
    }

    return (
      <div>
        <ReactPlayer
          url={this.props.playlist[0].src}
          playing={this.state.playing}
          height={0}
        />
        <Button onClick={this.playController} color="primary">
          {buttonText}
        </Button>
      </div>
    )
  }
}
