import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Media } from "reactstrap"
import SongCard from "../components/songCard"

var styles = {
  img: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
  },
  player: {
    songTitle: {
      display: "inline",
    },
  },
}

let playlist = [
  [
    {
      src: "http://www.hochmuth.com/mp3/Tchaikovsky_Nocturne__orch.mp3",
      title: "If I'm Lucky",
      artist: "Stereo Aage",
      cover:
        "https://res.cloudinary.com/pgoodjohn/image/upload/v1570993207/MattiaSerchione/Assets/covers/Stereo_Age_-_22If_i_m_lucky_22.jpg",
      embed: (
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/693344497&color=%2335363b&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
      ),
    },
  ],
  [
    {
      src: "http://www.hochmuth.com/mp3/Vivaldi_Sonata_eminor_.mp3",
      title: "Aloud (Acoustic)",
      artist: "Lights From Above",
      cover:
        "https://res.cloudinary.com/pgoodjohn/image/upload/v1570993207/MattiaSerchione/Assets/covers/Lights_from_above_-_22Aloud_22.jpg",
      embed: (
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/693342184&color=%2335363b&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
      ),
    },
  ],
  [
    {
      src: "http://www.hochmuth.com/mp3/Beethoven_12_Variation.mp3",
      title: "Simbolo",
      artist: "Kairos",
      cover:
        "https://res.cloudinary.com/pgoodjohn/image/upload/v1570993209/MattiaSerchione/Assets/covers/Kairos_-_22Simbolo_22.jpg",
      embed: (
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/693340183&color=%2335363b&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
      ),
    },
  ],
  [
    {
      src: "http://www.hochmuth.com/mp3/Beethoven_12_Variation.mp3",
      title: "Musica Dal Morto",
      artist: "Fiorello Mannaia",
      cover:
        "https://res.cloudinary.com/pgoodjohn/image/upload/v1570993208/MattiaSerchione/Assets/covers/Fiorello_Mannaia_-_22Musica_dal_morto_22.jpg",
      embed: (
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/693643069&color=%2335363b&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
      ),
    },
  ],
  [
    {
      src: "",
      title: "Last Christmas",
      artist: "Beyond Gravity",
      cover:
        "https://res.cloudinary.com/pgoodjohn/image/upload/v1577803102/MattiaSerchione/Assets/covers/Beyond_gravity_last_christmas.jpg",
      embed: (<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/736334383&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>)

    },
  ],
  [
    {
      src: "",
      title: "Ermal Metadone",
      artist: "Fiorello Mannaia",
      cover:
        "https://res.cloudinary.com/pgoodjohn/image/upload/v1602847005/MattiaSerchione/Assets/covers/ermal_metadone.jpg",
      embed: (
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/911573284&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
      )
    }
  ]
]

const Music = () => (
  <Layout>
    <SEO title="Music" />
    <Container fluid={true}>
      <Row>
        <Col md={4}>
          <SongCard playlist={playlist[0]} />
        </Col>
        <Col md={4}>
          <SongCard playlist={playlist[1]} />
        </Col>
        <Col md={4}>
          <SongCard playlist={playlist[2]} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={{ size: 4 }}>
          <SongCard playlist={playlist[3]} />
        </Col>
        <Col md={{ size: 4 }}>
          <SongCard playlist={playlist[4]} />
        </Col>
        <Col md={{ size: 4 }}>
          <SongCard playlist={playlist[5]} />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Music
